import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send';
import { Button } from '@mui/material';
import '../footer/Footer.css'

function Footer() {
    return (
        <>
            <section className='footerCont'>
                <div className='container'>
                    <div className='logoFooter'>
                        <Link to="/" className='d-flex flex-column align-items-center mb-5' style={{ textDecoration: 'none' }}>
                            <img src="images/logo.png" className="img-fluid logo" alt="Logo" />
                            <h3 className='logoText mt-3 mb-3'>The Grt Token</h3>
                        </Link>

                        <Button variant="contained" className='btn_custom' startIcon={<SendIcon />}>Download GRT token</Button>

                        <ul className='footerNavbar'>
                            <li>
                                <Link to='/' className="foot_A"> Home </Link>
                            </li>
                            <li>
                                <Link to='/' className="foot_A"> Jobs </Link>
                            </li>
                            <li>
                                <Link to='/' className="foot_A"> Ecosystem </Link>
                            </li>
                        </ul>

                        <ul className='footerEnd'>
                            <li>
                                <Link to='/' className="foot_A"> Status </Link>
                            </li>
                            <li>
                                <Link to='/' className="foot_A"> Testnet </Link>
                            </li>
                            <li>
                                <Link to='/' className="foot_A"> Privacy Policy </Link>
                            </li>
                            <li>
                                <Link to='/' className="foot_A"> Terms Of Service </Link>
                            </li>
                            <li>
                                <Link to='/' className="foot_A"> Brand Assets </Link>
                            </li>
                            <li>
                                <Link to='/' className="foot_A"> Partnership Request </Link>
                            </li>
                            <li>
                                <Link to='/' className="foot_A"> Forum </Link>
                            </li>
                            <li>
                                <Link to='/' className="foot_A"> Security </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer