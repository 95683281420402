import { Button } from '@mui/material'
import React from 'react'
import SendIcon from '@mui/icons-material/Send';

function HostedServices() {
    return (
        <>
            <section style={{ background: '#0F0D22', textAlign: "center", padding: '60px 0 100px' }}>
                <img src="images/portal.png" style={{width: '70%'}} className='img-fluid' alt='Hosted-Services' />
                <h1 style={{ fontWeight: '900', color: '#7872b5', marginBottom: '25px' }}>
                    Sub GRT Tokens have a new home
                </h1>
                <p style={{fontSize: '20px',width: '50%', margin: '0 auto 30px', color: '#807f7f'}}>
                    Hosted service endpoints are now unavailable. Start using and querying subgraphs on the decentralized network today.
                </p>
                <Button variant="contained" className='btn_custom' style={{marginRight: '20px'}} endIcon={<SendIcon />}>
                    Find Sub GRT Tokens in Explorer
                </Button>

                <Button variant="outlined" className='btn_custom'>
                    Learn about the Network
                </Button>

            </section>
        </>
    )
}

export default HostedServices