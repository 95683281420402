import React from 'react';
import '../sharedSections/WhyGrt.css'

function WhyGrt() {
    return (
        <>
            <div className='tokenCont'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xs-12 col-md-4 col-lg-4 cardContent'>
                            <img src='images/step_1.png' className='img-fluid' alt='GRT' />
                            <h2>
                                Build faster
                                <span className='text-secondary'> 100% less servers </span>
                            </h2>
                            <p>
                                GRT makes it easy for everybody to use and utilize our services. Our code is available in many programming languages so it's easy to adopt for every developer. Create noteworthy applications without expecting to run your own information server, construct ordering foundation, or parse through crude information.
                            </p>
                        </div>

                        <div className='col-xs-12 col-md-4 col-lg-4 cardContent'>
                            <img src='images/step_1.png' className='img-fluid' alt='GRT' />
                            <h2>
                                Optimized Routing
                                <span className='text-secondary'> 60-98% less per month </span>
                            </h2>
                            <p>
                                The GRT network generally tracks down the most ideal course to it's objective. Utilizing our own "closeness directing" calculation, information is continuously taking the quickest relayers.
                            </p>
                        </div>

                        <div className='col-xs-12 col-md-4 col-lg-4 cardContent'>
                            <img src='images/step_1.png' className='img-fluid' alt='GRT' />
                            <h2>
                                Increase resilience
                                <span className='text-secondary'> 99.99%+ uptime </span>
                            </h2>
                            <p>
                                GRT works without the immediate utilization of an IP address. Information is put away just in the nearby gadget, and consistently ensures best assurance of protection and security.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WhyGrt