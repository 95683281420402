import logo from './logo.svg';
import './App.css';
import {RouterProvider,} from "react-router-dom";
import router from './routing/router';

function App() {
  // const navScroll = () => {
  //   console.log(window.scrollY)
  //   // if(window.scrollY >= 80){
  //   //   console.log('scrolled')
  //   // }
  // }

  // window.addEventListener('scroll', navScroll)
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
