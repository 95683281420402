import React, { useState } from 'react'
// import { logo } from '../../../public/images/logo.png'
import { Link, NavLink } from 'react-router-dom'
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Main from '../Main'
import '../header/Header.css'
import '../Fonts.css'

export default function Header() {
    const [navHead, setNavHead] = useState(false)
    const [showNav, setShowNav] = useState(false)

    const navScroll = () => {
        console.log(window.scrollY)
        if (window.scrollY >= 80) {
            console.log('scrolled')
            setNavHead(true)
        } else {
            setNavHead(false)
        }
    }
    window.addEventListener('scroll', navScroll)

    const openMenuBar = () => {
        setShowNav(!showNav)
    }

    return (
        <>
            <header className={navHead ? 'active' : ''}>
                <div>
                    <Link to="/" className='d-flex align-items-center' style={{ textDecoration: 'none' }}>
                        <img src="images/logo.png" className="img-fluid logo" alt="Logo" />
                        <h3 className='logoText'>The Grt Token</h3>
                    </Link>
                </div>

                <nav className='navBar'>
                    <ul>
                        <li>
                            <NavLink to="/"
                                className={({ isActive }) => (isActive ? "customtext noLink" : "activeLink noLink")} >
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to="/products"
                                className={({ isActive }) => (isActive ? "customtext noLink" : "activeLink noLink")} >
                                Products
                            </NavLink>
                            <ul className='dropdown'>
                                <li>
                                    <NavLink>
                                        Studio
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink>
                                        Explorer
                                    </NavLink>
                                </li>

                                <li>
                                    <NavLink to='/hosted-services'>
                                        Hosted Service
                                    </NavLink>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <NavLink to="/about" className={({ isActive }) => (isActive ? "customtext noLink" : "activeLink noLink")}> About Us </NavLink>
                        </li>

                        {/* <li>
                            <NavLink to="/studio" className={({ isActive }) => (isActive ? "customtext noLink" : "activeLink noLink")}> Studio Pricing </NavLink>
                        </li> */}

                        {/* <li>
                            <NavLink to="/ecosystem" className={({ isActive }) => (isActive ? "customtext noLink" : "activeLink noLink")}> Ecosystem </NavLink>
                        </li> */}

                        <li>
                            <NavLink to="/docs" className={({ isActive }) => (isActive ? "customtext noLink" : "activeLink noLink")}> Docs </NavLink>
                        </li>

                        <li>
                            <Button variant="contained" className='btn_custom' startIcon={<SendIcon />}>Download</Button>
                        </li>
                    </ul>
                </nav>

                <IconButton
                    size="large"
                    edge="start"
                    className='mobileMenu'
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={openMenuBar}
                >
                    <MenuIcon />
                </IconButton>
            </header>

            {showNav ?
                <div className='mobile_menuList'>
                    <ul>
                        <li>
                            <NavLink to="/"
                                className={({ isActive }) => (isActive ? "customtext noLink" : "activeLink noLink")} >
                                Home
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to="/products"
                                className={({ isActive }) => (isActive ? "customtext noLink" : "activeLink noLink")} >
                                Products
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to='/hosted-services' className={({ isActive }) => (isActive ? "customtext noLink" : "activeLink noLink")} >
                                Hosted Service
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to="/about" className={({ isActive }) => (isActive ? "customtext noLink" : "activeLink noLink")}> About Us </NavLink>
                        </li>

                        {/* <li>
                            <NavLink to="/studio" className={({ isActive }) => (isActive ? "customtext noLink" : "activeLink noLink")}> Studio Pricing </NavLink>
                        </li>

                        <li>
                            <NavLink to="/ecosystem" className={({ isActive }) => (isActive ? "customtext noLink" : "activeLink noLink")}> Ecosystem </NavLink>
                        </li> */}

                        <li>
                            <NavLink to="/docs" className={({ isActive }) => (isActive ? "customtext noLink" : "activeLink noLink")}> Docs </NavLink>
                        </li>

                        <li>
                            <Button variant="contained" className='btn_custom' startIcon={<SendIcon />}>Download</Button>
                        </li>
                    </ul>
                </div>
                :
                ''
            }
        </>
    )
}


