import React from 'react'
import { Button } from '@mui/material'
import '../components/Home.css'
import WhyGrt from './sharedSections/WhyGrt'
import SupportedItems from './sharedSections/SupportedItems'

function Home() { 
  return (
    <>
      <section className='bannerCont'>
        <img src="images/banner.jpg" className='img-fluid' alt='' />
        <div className='contentData'>
          <h1>
            Access the world's
            blockchain data
          </h1>
          <div className='d-flex mt-5 matButtonCont'>
            <Button variant="contained" className="me-3 btn_custom">Explore Data</Button>
            <Button variant="contained" className='btn_custom'>Get Started</Button>
          </div>
        </div>
      </section>

      <WhyGrt />

      <section className='chooseUs'>
        <div className='container'>
          <h1> Why Choose GRT ? </h1>
          <div className='d-flex justify-content-between neoChoose'>
            <div className='boxLeft'>
              <div className='headingCont'>
                <h2>Low Fees & High Profits</h2>
                <p>
                  Unlock your financial potential with our platform's low fees and high-profit opportunities. Experience the power of maximizing returns while minimizing costs.
                </p>
                <img src="images/wc1.jpg" className='img-fluid' alt='GRT' />
              </div>
            </div>

            <div className='rightCont_box'>
              <div className='boxRight'>
                <div className='cardBox'>
                  <img src='images/wc2.svg' className='img-fluid' alt='GRT' />
                  <h2> Exposure </h2>
                  <p>
                    Diversify your portfolio effortlessly with our platform's extensive exposure options.
                  </p>
                </div>

                <div className='cardBox' style={{ background: '#BBDCF8' }}>
                  <img src='images/wc3.svg' className='img-fluid' alt='GRT' />
                  <h2> Token Distribution </h2>
                  <p>
                    Enable fair access and equal opportunity with our transparent token distribution.
                  </p>
                </div>
              </div>

              <div className='boxBottom'>
                <div className='btmBxCont'>
                  <div className='d-flex flex-column neoContent' style={{ width: '70%', marginRight: '20px' }}>
                    <h1>World Class Security System</h1>
                    <p>
                      Sub GRT Tokens are open APIs on The Diagram that sort out and serve blockchain information to applications. Utilizing subgraphs, engineers and information purchasers the same advantage from expedient admittance to recorded information.
                    </p>
                  </div>
                  <img src='images/wc4.svg' className='img-fluid' alt='GRT' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='whatIsGrt'>
        <div className='container'>
          <h1>
            What are GRT tokens and how do they work?
          </h1>

          <div className='cardContainer'>
            <h2>
              GRT tokens are alluded to as stablecoins in light of the fact that they offer cost security as they are fixed to a government issued money. This offers brokers, shippers and assets a low unpredictability arrangement when leaving positions on the lookout.
              <br />
              All GRT tokens are fixed at 1-to-1 with a matching government issued money (e.g., 1 USD₮ = 1 GRT) and are upheld 100 percent by GRT's stores.
              <br />
              As a fully transparent company, we publish a record of the current reserve assets.
            </h2>
          </div>

          <div className='row align-items-center'>
            <div className='col-xs-12 col-lg-6'>
              <img src='images/crypto-portfolio.png' className='img-fluid' alt='GRT' />
            </div>
            <div className='col-xs-12 col-lg-6 h2_heading'>
              <h2>
                More stability, more growth
              </h2>
              <p>
                GRT tokens have filled in notoriety throughout recent years, with a market cap of more than US$100 billion (as of Walk 2024). GRT tokens permit clients the capacity to execute across various blockchains, without the inborn instability and intricacy commonly connected with computerized tokens.
              </p>
              <Button variant="contained" className="me-3 btn_custom">Explore Data</Button>
            </div>
          </div>

          <div className='row align-items-center'>
            <div className='col-xs-12 col-lg-6 h2_heading'>
              <h2>
                Blockchain ready
              </h2>
              <p>
                GRT tokens exist as advanced tokens based on different blockchains including Algorand, Torrential slide, Celo, Universe, Ethereum, EOS, Fluid Organization, Close, Polkadot, Solana, Tezos, Ton, and Tron. Consequently, issuance of Tie tokens is reasonable on different blockchains with changing capacities relying upon the vehicle convention utilized.
              </p>
              <Button variant="contained" className="me-3 btn_custom">Explore Docs</Button>
            </div>
            <div className='col-xs-12 col-lg-6'>
              <img src='images/crypto-portfolio1.png' className='img-fluid' alt='GRT' />
            </div>
          </div>
        </div>
      </section>

      <SupportedItems/>
    </>
  )
}

export default Home