import React from 'react';
import { createHashRouter } from "react-router-dom";
import Main from '../components/Main';
import About from '../components/About';
import Home from '../components/Home';
import Products from '../components/Products';
import HostedServices from '../components/HostedServices';
import Docs from '../components/Docs';

const router = createHashRouter([
    {
        path: "/", element: <Main />, children: [
            { path: "/", element: <Home /> },
            { path: "/about", element: <About /> },
            { path: "products", element: <Products /> },
            { path: "hosted-services", element: <HostedServices /> },
            {path: "docs", element: <Docs/>}
        ]
    },

]);

export default router;
