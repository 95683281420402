import React, { useState } from 'react'
import Header from './header/Header'
import 'bootstrap/dist/css/bootstrap.min.css';
import Footer from './footer/Footer';
import { Outlet } from 'react-router-dom';

export default function Main() {
  return (
    <>
        <Header></Header>
        <Outlet/>
        <Footer/>
    </>
  )
}
