import React from 'react';
import '../components/About.css'
import TokenStory from './sharedSections/TokenStory';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#2d2d2d',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

function Products() {
  return (
    <>
      <section className='banner_Cont'>
        <div className='abt'>
          <h2> Products </h2>
          <img src='images/aboutUs.png' className='img-fluid' alt='GRT' />
        </div>
      </section>

      <section className='abt_content'>
        <div className='container'>
          <div className='row'>
            <div className='col-xs-12 col-lg-7'>
              <h4 style={{ width: '80%' }}>
                Connect your wallet
              </h4>
              <Stack direction="row" spacing={2}>
                <Item style={{width: '250px'}}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src='images/coinbase.png' style={{width: '24%'}} className='img-fluid' alt="coinbase" />

                    <p style={{marginLeft: '15px', marginBottom: '0', fontSize: '18px', color: '#a276ef'}}>Coinbase Wallet</p>
                  </div>
                </Item>
                <Item style={{width: '250px'}}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src='images/trust.png' style={{width: '24%'}} className='img-fluid' alt="trust wallet" />

                    <p style={{marginLeft: '15px', marginBottom: '0', fontSize: '18px', color: '#a276ef'}}>Trust Wallet</p>
                  </div>
                </Item>
              </Stack>

              <p style={{color: '#fefefe', fontSize: '14px', marginTop: '40px', marginBottom: '40px'}}> 
                By connecting a wallet, you agree to The Graph's Terms of Service and acknowledge that you have read and understood the disclaimers therein.
              </p>
            </div>

            <section className='whyUs mt-5'>
              <h2>GRT Ecosystem for Everyone</h2>
              <h4>
                Whether it is for personal use or business purposes, GRT tokens offer many
                benefits as the most stable, liquid and trusted stablecoin.
              </h4>
              <div className='row mt-5'>
                <div className='col-xs-12 col-lg-4'>
                  <div className='contentWhyUs'>
                    <img src='../images/triple_circle.svg' className='img-fluid' alt='GRT' />
                    <h4> Multiple blockchains support </h4>
                    <p className='text-secondary'>
                      GRT tokens are built on multiple blockchains—offering easy integration and adoption. Supported blockchains are Algorand, Avalanche, Bitcoin, Ethereum, EOS, Kava, Polka, Polygon, Solana, TRON and Tezos.
                    </p>
                  </div>
                </div>

                <div className='col-xs-12 col-lg-4'>
                  <div className='contentWhyUs'>
                    <img src='../images/coin_stack.svg' className='img-fluid' alt='GRT' />
                    <h4> Unparalleled liquidity </h4>
                    <p className='text-secondary'>
                      GRT tokens are among the most traded tokens in terms of daily volume, offering unequalled liquidity.
                    </p>
                  </div>
                </div>

                <div className='col-xs-12 col-lg-4'>
                  <div className='contentWhyUs'>
                    <img src='../images/wallet.svg' className='img-fluid' alt='GRT' />
                    <h4> Widespread Adoption </h4>
                    <p className='text-secondary'>
                      From exchanges and digital wallet apps to decentralised finance (DeFi) protocols and payment services, GRT tokens offer a smart alternative to fiat gateways.
                    </p>
                  </div>
                </div>

                <div className='col-xs-12 col-lg-4'>
                  <div className='contentWhyUs'>
                    <img src='../images/shield.svg' className='img-fluid' alt='GRT' />
                    <h4> 100% backed by GRT reserves </h4>
                    <p className='text-secondary'>
                      All GRT tokens are pegged at 1-to-1 with a matching fiat currency (e.g., 1 GRT = 1 USD) and are backed 100% by GRT’s reserves. The reserves match or exceed the amount required to redeem all GRT Token tokens in circulation.
                    </p>
                  </div>
                </div>

                <div className='col-xs-12 col-lg-4'>
                  <div className='contentWhyUs'>
                    <img src='../images/transparent_eye.svg' className='img-fluid' alt='GRT' />
                    <h4> Fully transparent </h4>
                    <p className='text-secondary'>
                      GRT Token tokens’ issued and reserve assets are publicly available and updated.
                    </p>
                  </div>
                </div>

                <div className='col-xs-12 col-lg-4'>
                  <div className='contentWhyUs'>
                    <img src='../images/world.svg' className='img-fluid' alt='GRT' />
                    <h4> Available in different currencies </h4>
                    <p className='text-secondary'>
                      GRT supports US dollar (USD), euro (EUR), Mexican Peso (MXN), and offshore Chinese yuan (CNH). Represented by ₮, GRT Token tokens are denoted as USD₮, EUR₮, MXN₮, CNH₮.
                    </p>
                  </div>
                </div>

                <div className='col-xs-12 col-lg-4'>
                  <div className='contentWhyUs'>
                    <img src='../images/Checkmark.svg' className='img-fluid' alt='GRT' />
                    <h4> Regulatory-compliant </h4>
                    <p className='text-secondary'>
                      GRT maintains world-class standardised compliance measures for anti-money laundering (AML), countering the financing of terrorism (CFT), sanctions, and know your customer (KYC) laws and regulations.
                    </p>
                  </div>
                </div>

                <div className='col-xs-12 col-lg-4'>
                  <div className='contentWhyUs'>
                    <img src='../images/Question_Circle.svg' className='img-fluid' alt='GRT' />
                    <h4> Best-in-class customer support </h4>
                    <p className='text-secondary'>
                      A highly-trained customer support team is ready to help you 24/7.
                    </p>
                  </div>
                </div>
              </div>

            </section>
          </div>
        </div>
      </section>

      <TokenStory />

    </>
  )
}

export default Products