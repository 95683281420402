import React from 'react'
import '../sharedSections/TokenStory.css'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Button } from '@mui/material';

function TokenStory() {
    return (
        <>
            <section className='tokenStory'>
                <div className='blackCurtain'>
                    <div className='container'>
                        <h2 style={{color: '#9160e8', fontWeight: '900'}}> The GRT token that is disrupting the global financial industry </h2>
                        <div className='row'>
                            <div className='col-xs-12 col-lg-4'>
                                <div className='contentWhyUs'>
                                    <img src='../images/triple_circle.svg' className='img-fluid' alt='GRT' />
                                    <h4> GRT for Individuals </h4>
                                    <p className='text-secondary'>
                                        GRT tokens offer exceptional liquidity on tier one exchanges giving traders the ability to take advantage of arbitrage opportunities in the fastest time possible.
                                    </p>
                                    <Button variant="text" style={{color: '#9160e8'}} endIcon={<ArrowRightAltIcon />}> Learn More </Button>
                                </div>
                            </div>

                            <div className='col-xs-12 col-lg-4'>
                                <div className='contentWhyUs'>
                                    <img src='../images/triple_circle.svg' className='img-fluid' alt='GRT' />
                                    <h4> GRT for Merchants </h4>
                                    <p className='text-secondary'>
                                        For merchants, integrating GRT Token tokens opens up an array of opportunities for consumers to purchase products and services.
                                    </p>
                                    <Button variant="text" style={{color: '#9160e8'}} endIcon={<ArrowRightAltIcon />}> Learn More </Button>
                                </div>
                            </div>

                            <div className='col-xs-12 col-lg-4'>
                                <div className='contentWhyUs'>
                                    <img src='../images/triple_circle.svg' className='img-fluid' alt='GRT' />
                                    <h4> GRT for Exchanges </h4>
                                    <p className='text-secondary'>
                                        GRT tokens play a pivotal role in the digital token ecosystem and are the most actively traded in terms of 24-hour volume.
                                    </p>
                                    <Button variant="text" style={{color: '#9160e8'}} endIcon={<ArrowRightAltIcon />}> Learn More </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TokenStory