import React from 'react'
import './Docs.css';
import Accordion from 'react-bootstrap/Accordion';

function Docs() {
    return (
        <>
            <section className='docsContainer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xs-12 col-lg-3'>
                            <ul className='contentUl'>
                                <li>
                                    About the Grt Token
                                </li>

                                <li>
                                    The Grt Token Network
                                </li>

                                <li>
                                    The Grt Token Network Delegating
                                </li>

                                <li>
                                    The Grt Token Network Curating
                                </li>

                                <li>
                                    Billing
                                </li>

                                <li>
                                    Tokenomics of The Grt Token Network
                                </li>
                            </ul>
                        </div>

                        <div className='col-xs-12 col-lg-9'>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', alignItems: 'center' }} >
                                            <h1 className='docH1' style={{ margin: 0 }}>About The Graph</h1>
                                            <span>Reading time: 4 min</span>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body style={{ background: '#151515' }}>
                                        <div className='contentCont'>
                                            <h1 className='docH1'>What is The Graph?</h1>
                                            <p>
                                                The Graph is a powerful decentralized protocol that enables seamless querying and indexing of blockchain data. It simplifies the complex process of querying blockchain data, making dapp development faster and easier.
                                            </p>

                                            <h1 className='docH1'>Understanding the Basics</h1>

                                            <p>
                                                Projects with complex shrewd agreements, for example, Uniswap and NFTs drives like Exhausted Gorilla Yacht Club store information on the Ethereum blockchain, making it truly challenging to peruse something besides fundamental information straightforwardly from the blockchain.
                                            </p>

                                            <h1 className='docH1'>Challenges Without The Graph</h1>
                                            <p>
                                                On account of the model recorded above, Exhausted Gorilla Yacht Club, you can perform essential read procedure on the agreement. You can peruse the proprietor of a specific Gorilla, read the substance URI of a Chimp in view of their ID, or read the complete stockpile.
                                            </p>

                                            <ol>
                                                <li>
                                                    This should be possible on the grounds that these read activities are customized straight forwardly into the shrewd agreement itself. Be that as it may, further developed, explicit, and genuine inquiries and tasks like conglomeration, search, connections, and non-trifling separating, are impractical.
                                                </li>

                                                <li>
                                                    For example, to ask about Gorillas claimed by a particular location and refine your pursuit in light of a specific trademark, you wouldn't have the option to get that data by straightforwardly cooperating with the actual agreement.
                                                </li>

                                                <li>
                                                    To get more information, you would need to deal with each and every exchange occasion at any point transmitted, read the metadata from IPFS utilizing the Symbolic ID and IPFS hash, and afterward total it.
                                                </li>
                                            </ol>


                                            <h1 className='docH1'> Why is this a problem? </h1>
                                            <p>
                                                It would require hours or even days for a decentralized application (dapp) running in a program to find a solution to these straightforward inquiries.
                                            </p>

                                            <p>
                                                On the other hand, you have the choice to set up your own server, process the exchanges, store them in a data set, and make a Programming interface endpoint to question the information. In any case, this choice is asset serious, needs upkeep, presents a weak link, and breaks significant security properties expected for decentralization.
                                            </p>

                                            <p>
                                                Blockchain properties, like absolution, chain rearrangements, and uncled blocks, add intricacy to the cycle, making it tedious and reasonably testing to recover precise inquiry results from blockchain information.
                                            </p>

                                            <h1 className='docH1'>The Graph Provides a Solution</h1>

                                            <p>
                                                The Graph solves this challenge with a decentralized protocol that indexes and enables the efficient and high-performance querying of blockchain data. These APIs (indexed "subgraphs") can then be queried with a standard GraphQL API.
                                            </p>

                                            <p>
                                                Today, there is a decentralized protocol that is backed by the open source implementation of Graph Node that enables this process.
                                            </p>

                                            <h1 className='docH1'> How The Graph Functions </h1>

                                            <p>
                                                Indexing blockchain data is very difficult, but The Graph makes it easy. The Graph learns how to index Ethereum data by using subgraphs. Subgraphs are custom APIs built on blockchain data that extract data from a blockchain, processes it, and stores it so that it can be seamlessly queried via GraphQL.
                                            </p>

                                            <h1 className='docH1'> Specifics </h1>

                                            <ol>
                                                <li>
                                                    The Graph uses subgraph descriptions, which are known as the subgraph manifest inside the subgraph.
                                                </li>

                                                <li>
                                                    The subgraph description outlines the smart contracts of interest for a subgraph, the events within those contracts to focus on, and how to map event data to the data that The Graph will store in its database.
                                                </li>

                                                <li>
                                                    When creating a subgraph, you need to write a subgraph manifest.
                                                </li>

                                                <li>
                                                    After writing the subgraph manifest, you can use the Graph CLI to store the definition in IPFS and instruct an Indexer to start indexing data for that subgraph.
                                                </li>
                                            </ol>

                                            <p>
                                                The diagram below provides more detailed information about the flow of data after a subgraph manifest has been deployed with Ethereum transactions.
                                            </p>

                                            <img src="../images/docs-illustrate.png" className='img-fluid' style={{ display: 'block', margin: '40px auto' }} alt='GRT-Token' />

                                            <p>
                                                The flow follows these steps:
                                            </p>
                                            <ol>
                                                <li>
                                                    A dapp adds data to Ethereum through a transaction on a smart contract.
                                                </li>
                                                <li>
                                                    The smart contract emits one or more events while processing the transaction.
                                                </li>
                                                <li>
                                                    Graph Node continually scans Ethereum for new blocks and the data for your subgraph they may contain.
                                                    Graph Node finds Ethereum events for your subgraph in these blocks and runs the mapping handlers you provided. The mapping is a WASM module that creates or updates the data entities that Graph Node stores in response to Ethereum events.
                                                </li>
                                                <li>
                                                    The dapp queries the Graph Node for data indexed from the blockchain, using the node's GraphQL endpoint. The Graph Node in turn translates the GraphQL queries into queries for its underlying data store in order to fetch this data, making use of the store's indexing capabilities. The dapp displays this data in a rich UI for end-users, which they use to issue new transactions on Ethereum. The cycle repeats.
                                                </li>
                                            </ol>

                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', alignItems: 'center' }} >
                                            <h1 className='docH1' style={{ margin: 0 }}>The Graph Network</h1>
                                            <span>Reading time: 9 min</span>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body style={{ background: '#151515' }}>
                                        <div className='contentCont'>
                                            <h1 className='docH1'>Network Overview</h1>
                                            <p>
                                                The Graph Network is a decentralized indexing protocol for organizing blockchain data. Applications use GraphQL to query open APIs called subgraphs and retrieve data that is indexed on the network. With The Graph, developers can build serverless applications that run entirely on public infrastructure.
                                            </p>

                                            <h1 className='docH1'>Overview</h1>

                                            <p>
                                                The Graph Network consists of Indexers, Curators, and Delegators that provide services to the network and serve data to Web3 applications. Consumers use the applications and consume the data.
                                            </p>

                                            <div className='col-xs-12 col-lg-12'>
                                                <img src="../images/dev.png" className='img-fluid' alt="Dev" />
                                            </div>

                                            <p>
                                                To ensure economic security of The Graph Network and the integrity of data being queried, participants stake and use Graph Tokens (GRT). GRT is a work utility token that is an ERC-20 used to allocate resources in the network.
                                            </p>

                                            <p>
                                                Active Indexers, Curators, and Delegators can provide services and earn income from the network, proportional to the amount of work they perform and their GRT stake.
                                            </p>

                                            <h1 className='docH1'> The Graph Network vs. Self Hosting </h1>
                                            <p>
                                                The Graph’s decentralized network has been engineered and refined to create a robust indexing and querying experience—and it’s getting better every day thanks to thousands of contributors around the world.
                                            </p>

                                            <p>
                                                The benefits of this decentralized protocol cannot be replicated by running a graph-node locally. The Graph Network is more reliable, more efficient, and less expensive.
                                            </p>

                                            <p>
                                                Here is an analysis:
                                            </p>

                                            <h1 className='docH1'>Why You Should Use The Graph Network⁠</h1>

                                            <ol>
                                                <li>
                                                    Significantly lower monthly costs
                                                </li>

                                                <li>
                                                    $0 infrastructure setup costs
                                                </li>

                                                <li>
                                                    Superior uptime
                                                </li>

                                                <li>
                                                    Access to hundreds of independent Indexers around the world
                                                </li>

                                                <li>
                                                    24/7 technical support by global community
                                                </li>
                                            </ol>

                                            <h1 className='docH1'> The Benefits Explained </h1>
                                            <h3 style={{ color: '#616161' }}>Lower & more Flexible Cost Structure</h3>

                                            <p>
                                                Estimated costs are only for Ethereum Mainnet subgraphs — costs are even higher when self hosting a graph-node on other networks. Some users may need to update their subgraph to a new version. Due to Ethereum gas fees, an update costs ~$50 at time of writing. Note that gas fees on Arbitrum are substantially lower than Ethereum mainnet.
                                            </p>

                                            <p>
                                                Curating signal on a subgraph is an optional one-time, net-zero cost (e.g., $1k in signal can be curated on a subgraph, and later withdrawn—with potential to earn returns in the process).
                                            </p>

                                            <h1 className='docH1'>
                                                No Setup Costs & Greater Operational Efficiency
                                            </h1>

                                            <p>
                                                Zero setup fees. Get started immediately with no setup or overhead costs. No hardware requirements. No outages due to centralized infrastructure, and more time to concentrate on your core product . No need for backup servers, troubleshooting, or expensive engineering resources.
                                            </p>

                                            <h1 className='docH1'> Reliability & Resiliency </h1>

                                            <ol>
                                                <li>
                                                    The Graph’s decentralized network gives users access to geographic redundancy that does not exist when self-hosting a graph-node. Queries are served reliably thanks to 99.9%+ uptime, achieved by hundreds of independent Indexers securing the network globally.
                                                </li>

                                                <li>
                                                    Bottom line: The Graph Network is less expensive, easier to use, and produces superior results compared to running a graph-node locally.
                                                </li>

                                                <li>
                                                    Start using The Graph Network today, and learn how to upgrade your subgraph to The Graph's decentralized network.
                                                </li>
                                            </ol>

                                            <h1 className='docH1'> Indexing </h1>
                                            <p>
                                                Indexers are node operators in The Graph Network that stake Graph Tokens (GRT) in order to provide indexing and query processing services. Indexers earn query fees and indexing rewards for their services. They also earn query fees that are rebated according to an exponential rebate function.
                                            </p>

                                            <p>
                                                GRT that is staked in the protocol is subject to a thawing period and can be slashed if Indexers are malicious and serve incorrect data to applications or if they index incorrectly. Indexers also earn rewards for delegated stake from Delegators, to contribute to the network.
                                            </p>

                                            <p>
                                                Indexers select subgraphs to index based on the subgraph’s curation signal, where Curators stake GRT in order to indicate which subgraphs are high-quality and should be prioritized. Consumers (eg. applications) can also set parameters for which Indexers process queries for their subgraphs and set preferences for query fee pricing.
                                            </p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', alignItems: 'center' }} >
                                            <h1 className='docH1' style={{ margin: 0 }}>The Graph Network Delegating</h1>
                                            <span>Reading time: 12 min</span>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body style={{ background: '#151515' }}>
                                        <div className='contentCont'>
                                            <h1 className='docH1'>Delegating </h1>
                                            <p>
                                                Delegators are network participants who delegate (i.e., "stake") GRT to one or more Indexers. Delegators help secure the network without running a Graph Node themselves.
                                            </p>

                                            <p>
                                                Delegators earn a portion of an Indexer's query fees and rewards by delegating to them. The amount of queries an Indexer can process depends on their own stake, the delegated stake, and the price the Indexer charges for each query. Therefore, the more stake that is allocated to an Indexer, the more potential queries they can process.
                                            </p>

                                            <h1 className='docH1'> Delegator Guide </h1>

                                            <p>
                                                This guide will explain how to be an effective Delegator in the Graph Network. Delegators share the earnings of the protocol alongside all Indexers based on their delegated stake. A Delegator must use their best judgment to choose Indexers based on multiple factors. Please note this guide will not cover steps such as setting up Metamask properly, as that information is widely available on the internet.
                                            </p>

                                            <p>
                                                There are three sections in this guide:
                                            </p>

                                            <ol>
                                                <li>
                                                    The risks of delegating tokens in The Graph Network
                                                </li>

                                                <li>
                                                    How to calculate expected returns as a Delegator
                                                </li>

                                                <li>
                                                    A video guide showing the steps to delegate in the Graph Network UI
                                                </li>
                                            </ol>

                                            <h1 className='docH1'> Delegation Risks </h1>
                                            <p>
                                                Listed below are the main risks of being a Delegator in the protocol.
                                            </p>

                                            <h3 style={{ color: '#616161' }}> The delegation tax </h3>

                                            <p>
                                                Delegators cannot be slashed for bad behavior, but there is a tax on Delegators to disincentivize poor decision-making that could harm the integrity of the network.
                                            </p>

                                            <p>
                                                It is important to understand that every time you delegate, you will be charged 0.5%. This means that if you are delegating 1000 GRT, you will automatically burn 5 GRT.
                                            </p>

                                            <p>
                                                In order to be safe, a Delegator should calculate their potential return when delegating to an Indexer. For example, a Delegator might calculate how many days it will take before they have earned back the 0.5% tax on their delegation.
                                            </p>

                                            <h1 className='docH1'>The delegation unbonding period⁠</h1>
                                            <p>
                                                Whenever a Delegator wants to undelegate, their tokens are subject to a 28-day unbonding period. This means they cannot transfer their tokens or earn any rewards for 28 days.
                                            </p>

                                            <p>
                                                Another thing to consider is how to choose an Indexer wisely. If you choose an Indexer that is not trustworthy or not doing a good job, you will want to undelegate. This means you will be losing a lot of opportunities to earn rewards, which can be just as bad as burning GRT.
                                            </p>

                                            <h1 className='docH1'> Choosing a trustworthy Indexer with a fair reward payout for Delegators </h1>

                                            <p>
                                                This is an important aspect to understand. First, let's discuss three very important values, which are the Delegation Parameters.
                                            </p>

                                            <p>
                                                Indexing Reward Cut - The indexing reward cut is the portion of the rewards that the Indexer will keep for themselves. This means that if an Indexer's rewards are set to 100%, as a Delegator you will get 0 indexing rewards. If you see it set at 80% in the UI, that means as a Delegator, you will receive 20%. An important note - at the beginning of the network, Indexing Rewards will account for the majority of the rewards.
                                            </p>

                                            <ol>
                                                <li>
                                                    Query Fee Cut - This works exactly like the Indexing Reward Cut. However, this applies explicitly to returns on the query fees the Indexer collects. It should be noted that at the start of the network, returns from query fees will be very small compared to the indexing reward. It is recommended that you pay attention to the network to determine when the query fees in the network will start to be more significant.
                                                </li>
                                            </ol>

                                            <p>
                                                As you can see, in order to choose the right Indexer, you must consider multiple things. This is why we highly recommend exploring The Graph Discord to determine which Indexers have the best social and technical reputations and which reward Delegators consistently. Many of the Indexers are very active in Discord and will be happy to answer your questions. Many of them have been Indexing for months on the testnet, and they are doing their best to help Delegators earn a good return, as it improves the health and success of the network.
                                            </p>

                                            <h1 className='docH1'>
                                                Calculating Delegators expected return
                                            </h1>

                                            <p>
                                                A Delegator must consider a lot of factors when determining the return. These include:
                                            </p>

                                            <ol>
                                                <li>
                                                    A technical Delegator can also look at the Indexer's ability to use the Delegated tokens available to them. If an Indexer is not allocating all the tokens available, they are not earning the maximum profit they could be for themselves or their Delegators.
                                                </li>

                                                <li>
                                                    Right now, in the network an Indexer can choose to close an allocation and collect rewards anytime between 1 and 28 days. So, it is possible that an Indexer might have a lot of rewards they still need to collect, and thus, their total rewards are low. This should be taken into consideration in the early days.
                                                </li>
                                            </ol>

                                            <h1 className='docH1'> Considering the query fee cut and indexing fee cut </h1>

                                            <p>
                                                As described in the above sections, you should choose an Indexer that is transparent and honest about setting their Query Fee Cut and Indexing Fee Cuts. A Delegator should also look at the Parameters Cooldown time to see how much of a time buffer they have. After that is done, it is fairly simple to calculate the amount of rewards the Delegators are getting. The formula is:
                                            </p>

                                            <h1 className='docH1'> Considering the Indexer's delegation pool </h1>
                                            <p>
                                                Another thing a Delegator has to consider is what proportion of the Delegation Pool they own. All delegation rewards are shared evenly, with a simple rebalancing of the pool determined by the amount the Delegator has deposited into the pool. This gives the Delegator a share of the pool:
                                            </p>

                                            <img src="../images/formula.png" className='img-fluid' alt="GRT" />

                                            <p>
                                                Using this formula, we can see that it is possible for an Indexer offering only 20% to Delegators to actually provide a better reward than an Indexer giving 90%.
                                            </p>

                                            <p>
                                                Therefore, a Delegator can do the math to determine that the Indexer offering 20% to Delegators is offering a better return.
                                            </p>

                                            <h1 className='docH1'> Considering the delegation capacity </h1>
                                            <p>
                                                Another thing to consider is the delegation capacity. Currently, the Delegation Ratio is set to 16. This means that if an Indexer has staked 1,000,000 GRT, their Delegation Capacity is 16,000,000 GRT of Delegated tokens that they can use in the protocol. Any delegated tokens over this amount will dilute all the Delegator rewards.
                                            </p>

                                            <p>
                                                Imagine an Indexer with 100,000,000 GRT delegated to them, but their capacity is only 16,000,000 GRT. This means effectively, 84,000,000 GRT tokens are not being used to earn tokens. So, all the Delegators and the Indexer, are earning way less rewards than they could be.
                                            </p>

                                            <p>
                                                Therefore, a Delegator should always consider the Delegation Capacity of an Indexer, and factor it into their decision making.
                                            </p>

                                            <h1 className='docH1'> Delegator FAQs and Bugs </h1>

                                            <p>
                                                When I try to delegate my transaction in MetaMask, it appears as "Pending" or "Queued" for longer than expected. What should I do?
                                            </p>

                                            <p>
                                                At times, attempts to delegate to indexers via MetaMask can fail and result in prolonged periods of "Pending" or "Queued" transaction attempts.
                                            </p>

                                            <p>
                                                For example, a user may attempt to delegate with an insufficient gas fee relative to the current prices, resulting in the transaction attempt displaying as "Pending" in their MetaMask wallet for 15+ minutes. When this occurs, a user can attempt subsequent transactions, but these will only be processed until the initial transaction is mined, as transactions for an address must be processed in order. In such cases, these transactions can be cancelled in MetaMask, but the transactions attempts will accrue gas fees without any guarantee that subsequent attempts will be successful.
                                            </p>

                                            <p>
                                                A simpler resolution to this bug is restarting the browser (e.g., using "abort:restart" in the address bar), which will cancel all previous attempts without gas being subtracted from the wallet. Several users who have encountered this issue have reported successful transactions after restarting their browser and attempting to delegate.
                                            </p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', alignItems: 'center' }} >
                                            <h1 className='docH1' style={{ margin: 0 }}>The Graph Network Curating</h1>
                                            <span>Reading time: 8 min</span>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body style={{ background: '#151515' }}>
                                        <div className='contentCont'>
                                            <h1 className='docH1'>Curating </h1>
                                            <p>
                                                Curators are critical to The Graph's decentralized economy. They use their knowledge of the web3 ecosystem to assess and signal on the subgraphs that should be indexed by The Graph Network. Through Graph Explorer, Curators view network data to make signaling decisions. In turn, The Graph Network rewards Curators who signal on good quality subgraphs with a share of the query fees those subgraphs generate. The amount of GRT signaled is one of the key considerations for indexers when determining which subgraphs to index.
                                            </p>

                                            <h1 className='docH1'> What Does Signaling Mean for The Graph Network? </h1>

                                            <p>
                                                Before consumers can query a subgraph, it must be indexed. This is where curation comes into play. In order for Indexers to earn substantial query fees on quality subgraphs, they need to know what subgraphs to index. When Curators signal on a subgraph, it lets Indexers know that a subgraph is in demand and of sufficient quality that it should be indexed.
                                            </p>

                                            <p>
                                                Indexers can trust the signal from a Curator because upon signaling, Curators mint a curation share for the subgraph, entitling Curators to a portion of future query fees that the subgraph drives.
                                            </p>

                                            <p>
                                                Curators make The Graph network efficient and signaling is the process that curators use to let Indexers know that a subgraph is good to index. Indexers can trust the signal from a curator because upon signaling, curators mint a curation share for the subgraph, entitling them to a portion of future query fees that the subgraph drives.
                                            </p>

                                            <p>
                                                Curator signals are represented as ERC20 tokens called Graph Curation Shares (GCS). Those that want to earn more query fees should signal their GRT to subgraphs that they predict will generate a strong flow of fees to the network. Curators cannot be slashed for bad behavior, but there is a deposit tax on Curators to disincentivize poor decision-making that could harm the integrity of the network. Curators will also earn fewer query fees if they curate on a low-quality subgraph because there will be fewer queries to process or fewer Indexers to process them.
                                            </p>

                                            <p>
                                                The Sunrise Upgrade Indexer ensures the indexing of all subgraphs, signaling GRT on a particular subgraph will draw more indexers to it. This incentivization of additional Indexers through curation aims to enhance the quality of service for queries by reducing latency and enhancing network availability.
                                            </p>

                                            <p>
                                                When signaling, Curators can decide to signal on a specific version of the subgraph or to signal using auto-migrate. If they signal using auto-migrate, a curator’s shares will always be updated to the latest version published by the developer. If they decide to signal on a specific version instead, shares will always stay on this specific version.
                                            </p>

                                            <p>
                                                If you require assistance with curation to enhance the quality of service, please send a request to the Edge & Node team at support@thegraph.zendesk.com and specify the subgraphs you need assistance with.
                                            </p>

                                            <p>
                                                Indexers can find subgraphs to index based on curation signals they see in Graph Explorer (screenshot below).
                                            </p>

                                            <img src="../images/curating.png" className='img-fluid' alt="" />

                                            <h1 className='docH1'> How to Signal⁠ </h1>
                                            <p>
                                                Within the Curator tab in Graph Explorer, curators will be able to signal and unsignal on certain subgraphs based on network stats. For a step-by-step overview of how to do this in Graph Explorer, click here.
                                            </p>

                                            <p>
                                                A curator can choose to signal on a specific subgraph version, or they can choose to have their signal automatically migrate to the newest production build of that subgraph. Both are valid strategies and come with their own pros and cons.
                                            </p>

                                            <p>
                                                Signaling on a specific version is especially useful when one subgraph is used by multiple dApps. One dApp might need to regularly update the subgraph with new features. Another dApp might prefer to use an older, well-tested subgraph version. Upon initial curation, a 1% standard tax is incurred.
                                            </p>

                                            <p>
                                                Having your signal automatically migrate to the newest production build can be valuable to ensure you keep accruing query fees. Every time you curate, a 1% curation tax is incurred. You will also pay a 0.5% curation tax on every migration. Subgraph developers are discouraged from frequently publishing new versions - they have to pay a 0.5% curation tax on all auto-migrated curation shares.
                                            </p>

                                            <h1 className='docH1'> Withdrawing your GRT </h1>

                                            <p>
                                                Curators have the option to withdraw their signaled GRT at any time.
                                            </p>

                                            <p>
                                                Unlike the process of delegating, if you decide to withdraw your signaled GRT you will not have to wait for a cooldown period and will receive the entire amount (minus the 1% curation tax).
                                            </p>

                                            <p>
                                                Once a curator withdraws their signal, indexers may choose to keep indexing the subgraph, even if there's currently no active GRT signaled.
                                            </p>

                                            <p>
                                                However, it is recommended that curators leave their signaled GRT in place not only to receive a portion of the query fees, but also to ensure reliability and uptime of the subgraph.
                                            </p>

                                            <h1 className='docH1'> Risks </h1>

                                            <ol>
                                                <li>
                                                    The query market is inherently young at The Graph and there is risk that your %APY may be lower than you expect due to nascent market dynamics.
                                                </li>

                                                <li>
                                                    Curation Fee - when a curator signals GRT on a subgraph, they incur a 1% curation tax. This fee is burned.
                                                    (Ethereum only) When curators burn their shares to withdraw GRT, the GRT valuation of the remaining shares will be reduced. Be aware that in some cases, curators may decide to burn their shares all at once. This situation may be common if a dApp developer stops versioning/improving and querying their subgraph or if a subgraph fails. As a result, remaining curators might only be able to withdraw a fraction of their initial GRT. For a network role with a lower risk profile, see Delegators.
                                                </li>

                                                <li>
                                                    A subgraph can fail due to a bug. A failed subgraph does not accrue query fees. As a result, you’ll have to wait until the developer fixes the bug and deploys a new version.
                                                </li>

                                                <li>
                                                    If you are subscribed to the newest version of a subgraph, your shares will auto-migrate to that new version. This will incur a 0.5% curation tax.
                                                </li>

                                                <li>
                                                    If you have signaled on a specific subgraph version and it fails, you will have to manually burn your curation shares. You can then signal on the new subgraph version, thus incurring a 1% curation tax.
                                                </li>
                                            </ol>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', alignItems: 'center' }} >
                                            <h1 className='docH1' style={{ margin: 0 }}> Billing </h1>
                                            <span>Reading time: 12 min</span>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body style={{ background: '#151515' }}>
                                        <div className='contentCont'>
                                            <h1 className='docH1'>Subgraph Billing Plans </h1>
                                            <p>
                                                There are two plans to use when querying subgraphs on The Graph Network.
                                            </p>

                                            <ol>
                                                <li>
                                                    Free Plan: The Free Plan includes 100,000 free monthly queries with full access to the Subgraph Studio testing environment. This plan is designed for hobbyists, hackathoners, and those with side projects to try out The Graph before scaling their dapp.
                                                </li>

                                                <li>
                                                    Growth Plan: The Growth Plan includes everything in the Free Plan with all queries after 100,000 monthly queries requiring payments with GRT or credit card. The Growth Plan is flexible enough to cover teams that have established dapps across a variety of use cases.
                                                </li>
                                            </ol>

                                            <h1 className='docH1'> Query Payments with credit card </h1>

                                            <ol>
                                                <li>
                                                    To set up billing with credit/debit cards, users will access Subgraph Studio (https://thegraph.com/studio/)
                                                </li>

                                                <li>
                                                    Go to the Subgraph Studio Billing page.
                                                </li>

                                                <li>
                                                    Click on the "Connect Wallet" button on the top right corner of the page. You'll be redirected to the wallet selection page. Select your wallet and click on "Connect".
                                                </li>

                                                <li>
                                                    Choose “Upgrade plan” if you are upgrading from the Free Plan or choose “Manage Plan” if you have already added GRT to your billing balance in the past. Next, you can estimate the number of queries to get a pricing estimate, but this is not a required step.
                                                </li>

                                                <li>
                                                    To choose a credit card payment, choose “Credit card” as the payment method and fill out your credit card information. Those who have used Stripe before can use the Link feature to autofill their details.
                                                </li>

                                                <li>
                                                    Invoices will be processed at the end of each month and require an active credit card on file for all queries beyond the free plan quota.
                                                </li>
                                            </ol>

                                            <h1 className='docH1'> Query Payments with GRT </h1>
                                            <p>
                                                Subgraph users can use The Graph Token (or GRT) to pay for queries on The Graph Network. With GRT, invoices will be processed at the end of each month and require a sufficient balance of GRT to make queries beyond the Free Plan quota of 100,000 monthly queries. You'll be required to pay fees generated from your API keys. Using the billing contract, you'll be able to:
                                            </p>

                                            <ol>
                                                <li>
                                                    Add and withdraw GRT from your account balance.
                                                </li>

                                                <li>
                                                    Keep track of your balances based on how much GRT you have added to your account balance, how much you have removed, and your invoices.
                                                </li>

                                                <li>
                                                    Automatically pay invoices based on query fees generated, as long as there is enough GRT in your account balance.
                                                </li>
                                            </ol>

                                            <h1 className='docH1'> GRT on Arbitrum or Ethereum </h1>

                                            <p>
                                                The Graph’s billing system accepts GRT on Arbitrum, and users will need ETH on Arbitrum to pay their gas. While The Graph protocol started on Ethereum Mainnet, all activity, including the billing contracts, is now on Arbitrum One.
                                            </p>

                                            <p>
                                                To pay for queries, you need GRT on Arbitrum. Here are a few different ways to achieve this:
                                            </p>

                                            <ol>
                                                <li>
                                                    If you already have GRT on Ethereum, you can bridge it to Arbitrum. You can do this via the GRT bridging option provided in Subgraph Studio or by using one of the following bridges:
                                                </li>

                                                <li>
                                                    The Arbitrum Bridge
                                                </li>

                                                <li>
                                                    TransferTo
                                                </li>

                                                <li>
                                                    If you already have assets on Arbitrum, you can swap them for GRT via a swapping protocol like Uniswap.
                                                </li>

                                                <li>
                                                    Alternatively, you acquire GRT directly on Arbitrum through a decentralized exchange.
                                                </li>
                                            </ol>

                                            <p>Once you bridge GRT, you can add it to your billing balance.</p>

                                            <h1 className='docH1'> Adding GRT using a wallet⁠ </h1>
                                            <ol>
                                                <li>
                                                    Go to the Subgraph Studio Billing page.
                                                </li>

                                                <li>
                                                    Click on the "Connect Wallet" button on the top right corner of the page. You'll be redirected to the wallet selection page. Select your wallet and click on "Connect".
                                                </li>

                                                <li>
                                                    Select the "Manage" button near the top right corner. First time users will see an option to "Upgrade to Growth plan" while returning users will click "Deposit from wallet".
                                                </li>

                                                <li>
                                                    Use the slider to estimate the number of queries you expect to make on a monthly basis.
                                                </li>

                                                <li>
                                                    For suggestions on the number of queries you may use, see our Frequently Asked Questions page.
                                                </li>

                                                <li>
                                                    Choose "Cryptocurrency". GRT is currently the only cryptocurrency accepted on The Graph Network.
                                                </li>

                                                <li>
                                                    Select the number of months you would like to prepay.
                                                </li>

                                                <li>
                                                    Paying in advance does not commit you to future usage. You will only be charged for what you use and you can withdraw your balance at any time.
                                                </li>

                                                <li>
                                                    Pick the network from which you are depositing your GRT. GRT on Arbitrum or Ethereum are both acceptable.
                                                    Click "Allow GRT Access" and then specify the amount of GRT that can be taken from you wallet.
                                                </li>

                                                <li>
                                                    If you are prepaying for multiple months, you must allow access to the amount that corresponds with that amount. This interaction will not cost any gas.
                                                </li>

                                                <li>
                                                    Lastly, click on "Add GRT to Billing Balance". This transaction will require ETH on Arbitrum to cover the gas costs.
                                                </li>

                                                <li>
                                                    Note that GRT deposited from Arbitrum will process within a few moments while GRT deposited from Ethereum will take approximately 15-20 minutes to process. Once the transaction is confirmed, you'll see the GRT added to your account balance.
                                                </li>
                                            </ol>

                                            <h1 className='docH1'> Withdrawing GRT using a wallet</h1>

                                            <ol>
                                                <li>
                                                    Go to the Subgraph Studio Billing page.
                                                </li>

                                                <li>
                                                    Click on the "Connect Wallet" button on the top right corner of the page. Select your wallet and click on "Connect".
                                                </li>

                                                <li>
                                                    Click the "Manage"" button at the top right corner of the page. Select "Withdraw GRT". A side panel will appear.
                                                </li>

                                                <li>
                                                    Enter the amount of GRT you would like to withdraw.
                                                </li>

                                                <li>
                                                    Click 'Withdraw GRT' to withdraw the GRT from your account balance. Sign the associated transaction in your wallet. This will cost gas. The GRT will be sent to your Arbitrum wallet.
                                                </li>

                                                <li>
                                                    Once the transaction is confirmed, you'll see the GRT withdrawn from your account balance in your Arbitrum wallet.
                                                </li>
                                            </ol>

                                            <h1 className='docH1'>Adding GRT using a multisig wallet ⁠</h1>
                                            <ol>
                                                <li>
                                                    Go to the Subgraph Studio Billing page.
                                                    Click on the "Connect Wallet" button on the top right corner of the page. Select your wallet and click on "Connect". If you're using Gnosis-Safe, you'll be able to connect your multisig as well as your signing wallet. Then, sign the associated message. This will not cost any gas.
                                                </li>

                                                <li>
                                                    Select the "Manage" button near the top right corner. First time users will see an option to "Upgrade to Growth plan" while returning users will click "Deposit from wallet".
                                                </li>

                                                <li>
                                                    Use the slider to estimate the number of queries you expect to make on a monthly basis.
                                                </li>

                                                <li>
                                                    For suggestions on the number of queries you may use, see our Frequently Asked Questions page.
                                                </li>

                                                <li>
                                                    Choose "Cryptocurrency". GRT is currently the only cryptocurrency accepted on The Graph Network.
                                                </li>

                                                <li>
                                                    Select the number of months you would like to prepay.
                                                </li>

                                                <li>
                                                    Paying in advance does not committing you to future usage. You will only be charged for what you use and you can withdraw your balance at any time.
                                                </li>

                                                <li>
                                                    Pick the network from which you are depositing your GRT. GRT on Arbitrum or Ethereum are both acceptable. 8. Click "Allow GRT Access" and then specify the amount of GRT that can be taken from you wallet.
                                                </li>

                                                <li>
                                                    If you are prepaying for multiple months, you must allow access to the amount that corresponds with that amount. This interaction will not cost any gas.
                                                </li>

                                                <li>
                                                    Lastly, click on "Add GRT to Billing Balance". This transaction will require ETH on Arbitrum to cover the gas costs.
                                                </li>

                                                <li>
                                                    Note that GRT deposited from Arbitrum will process within a few moments while GRT deposited from Ethereum will take approximately 15-20 minutes to process. Once the transaction is confirmed, you'll see the GRT added to your account balance.
                                                </li>
                                            </ol>

                                            <h1 className='docH1'> Choosing a trustworthy Indexer with a fair reward payout for Delegators </h1>

                                            <p>
                                                This is an important aspect to understand. First, let's discuss three very important values, which are the Delegation Parameters.
                                            </p>

                                            <p>
                                                Indexing Reward Cut - The indexing reward cut is the portion of the rewards that the Indexer will keep for themselves. This means that if an Indexer's rewards are set to 100%, as a Delegator you will get 0 indexing rewards. If you see it set at 80% in the UI, that means as a Delegator, you will receive 20%. An important note - at the beginning of the network, Indexing Rewards will account for the majority of the rewards.
                                            </p>

                                            <ol>
                                                <li>
                                                    Query Fee Cut - This works exactly like the Indexing Reward Cut. However, this applies explicitly to returns on the query fees the Indexer collects. It should be noted that at the start of the network, returns from query fees will be very small compared to the indexing reward. It is recommended that you pay attention to the network to determine when the query fees in the network will start to be more significant.
                                                </li>
                                            </ol>

                                            <p>
                                                As you can see, in order to choose the right Indexer, you must consider multiple things. This is why we highly recommend exploring The Graph Discord to determine which Indexers have the best social and technical reputations and which reward Delegators consistently. Many of the Indexers are very active in Discord and will be happy to answer your questions. Many of them have been Indexing for months on the testnet, and they are doing their best to help Delegators earn a good return, as it improves the health and success of the network.
                                            </p>

                                            <h1 className='docH1'>
                                                Getting GRT
                                            </h1>

                                            <p>
                                                This section will show you how to get GRT to pay for query fees.
                                            </p>

                                            <h1 className='docH1'>
                                                Coinbase
                                            </h1>
                                            <ol>
                                                <li>
                                                    This will be a step by step guide for purchasing GRT on Coinbase.
                                                </li>
                                                <li>
                                                    Go to Coinbase and create an account.
                                                </li>

                                                <li>
                                                    Once you have created an account, you will need to verify your identity through a process known as KYC (or Know Your Customer). This is a standard procedure for all centralized or custodial crypto exchanges.
                                                </li>

                                                <li>
                                                    Once you have verified your identity, you can purchase GRT. You can do this by clicking on the "Buy/Sell" button on the top right of the page.
                                                </li>

                                                <li>
                                                    Select the currency you want to purchase. Select GRT.
                                                </li>

                                                <li>
                                                    Select the payment method. Select your preferred payment method.
                                                </li>

                                                <li>
                                                    Select the amount of GRT you want to purchase.
                                                </li>

                                                <li>
                                                    Review your purchase. Review your purchase and click "Buy GRT".
                                                </li>

                                                <li>
                                                    Confirm your purchase. Confirm your purchase and you will have successfully purchased GRT.
                                                    You can transfer the GRT from your account to your wallet such as MetaMask.
                                                </li>

                                                <li>
                                                    To transfer the GRT to your wallet, click on the "Accounts" button on the top right of the page.
                                                    Click on the "Send" button next to the GRT account.
                                                </li>

                                                <li>
                                                    Enter the amount of GRT you want to send and the wallet address you want to send it to.
                                                </li>

                                                <li>
                                                    Click "Continue" and confirm your transaction. -Please note that for larger purchase amounts, Coinbase may require you to wait 7-10 days before transferring the full amount to a wallet.
                                                </li>

                                                <li>
                                                    You can learn more about getting GRT on Coinbase here.
                                                </li>
                                            </ol>

                                            <h1 className='docH1'> Trust Wallet </h1>

                                            <p>
                                                This will be a step by step guide for purchasing GRT on TRUST WALLET.
                                            </p>

                                            <ol>
                                                <li>
                                                    Go to Trust Wallet and create an account.
                                                </li>

                                                <li>
                                                    Once you have created an account, you will need to verify your identity through a process known as KYC (or Know Your Customer). This is a standard procedure for all centralized or custodial crypto exchanges.
                                                </li>

                                                <li>
                                                    Once you have verified your identity, you can purchase GRT. You can do this by clicking on the "Buy Now" button on the homepage banner.
                                                </li>

                                                <li>
                                                    You will be taken to a page where you can select the currency you want to purchase. Select GRT.
                                                </li>

                                                <li>
                                                    Select your preferred payment method. You'll be able to pay with different fiat currencies such as Euros, US Dollars, and more.
                                                </li>

                                                <li>
                                                    Select the amount of GRT you want to purchase.
                                                </li>

                                                <li>
                                                    Review your purchase and click "Buy GRT".
                                                </li>

                                                <li>
                                                    Confirm your purchase and you will be able to see your GRT in your Trust Wallet Spot Wallet.
                                                </li>

                                                <li>
                                                    You can withdraw the GRT from your account to your wallet such as MetaMask.
                                                </li>

                                                <li>
                                                    To withdraw the GRT to your wallet, add your wallet's address to the withdrawel whitelist.
                                                </li>

                                                <li>
                                                    Click on the "wallet" button, click withdraw, and select GRT.
                                                </li>

                                                <li>
                                                    Enter the amount of GRT you want to send and the whitelisted wallet address you want to send it to.
                                                    Click "Continue" and confirm your transaction.
                                                </li>

                                                <li>
                                                    You can learn more about getting GRT on Trust Wallet here.
                                                </li>
                                            </ol>

                                            <h1 className='docH1'> Billing FAQs </h1>

                                            <h1 className='docH1'> How many queries will I need? </h1>
                                            <p>
                                                You don't need to know how many queries you'll need in advance. You will only be charged for what you use and you can withdrawal GRT from your account at any time.
                                            </p>

                                            <p>
                                                We recommend you overestimate the number of queries you will need so that you don’t have to top up your balance frequently. A good estimate for small to medium sized applications is to start with 1M-2M queries per month and monitor usage closely in the first weeks. For larger apps, a good estimate is to use the number of daily visits your site gets multiplied by the number of queries your most active page makes upon opening.
                                            </p>

                                            <p>
                                                Of course, both new and existing users can reach out to Edge & Node's BD team for a consult to learn more about anticipated usage.
                                            </p>

                                            <h1 className='docH1'> Can I withdraw GRT from my billing balance? </h1>

                                            <p>
                                                Yes, you can always withdraw GRT that has not already been used for queries from your billing balance. The billing contract is only designed to bridge GRT from Ethereum mainnet to the Arbitrum network. If you'd like to transfer your GRT from Arbitrum back to Ethereum mainnet, you'll need to use the Arbitrum Bridge.
                                            </p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', alignItems: 'center' }} >
                                            <h1 className='docH1' style={{ margin: 0 }}> Tokenomics of The Grt Token </h1>
                                            <span>Reading time: 6 min</span>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body style={{ background: '#151515' }}>
                                        <div className='contentCont'>
                                            <h1 className='docH1'>Subgraph Billing Plans </h1>
                                            <p>
                                                The Graph is a decentralized protocol that enables easy access to blockchain data.
                                            </p>

                                            <p>
                                                It's similar to a B2B2C model, except it is powered by a decentralized network of participants. Network participants work together to provide data to end users in exchange for GRT rewards. GRT is the work utility token that coordinates data providers and consumers. GRT serves as a utility for coordinating data providers and consumers within the network and incentivizes protocol participants to organize data effectively.
                                            </p>

                                            <p>
                                                By using The Graph, users can easily access data from the blockchain, paying only for the specific information they need. The Graph is used by many popular dapps in the web3 ecosystem today.
                                            </p>

                                            <p>
                                                The Graph indexes blockchain data similarly to how Google indexes the web. In fact, you may already be using The Graph without realizing it. If you've viewed the front end of a dapp that gets its data from a subgraph, you queried data from a subgraph!
                                            </p>

                                            <p>
                                                The Graph plays a crucial role in making blockchain data more accessible and enabling a marketplace for its exchange.
                                            </p>

                                            <h1 className='docH1'>The Roles of Network Participants</h1>
                                            <ol>
                                                <li>
                                                    Delegators - Delegate GRT to Indexers & secure the network
                                                </li>

                                                <li>
                                                    Curators - Find the best subgraphs for Indexers
                                                </li>

                                                <li>
                                                    Developers - Build & query subgraphs
                                                </li>

                                                <li>
                                                    Indexers - Backbone of blockchain data
                                                </li>
                                            </ol>

                                            <p>
                                                Fishermen and Arbitrators are also integral to the network’s success through other contributions, supporting the work of the other primary participant roles. For more information about network roles, read this article.
                                            </p>

                                            <img src="../images/token.png" className='img-fluid' alt="Tokenomics" />

                                            <h1 className='docH1'> Delegators (Passively earn GRT) </h1>

                                            <p>
                                                Indexers are delegated GRT by Delegators increasing the Indexer’s stake in subgraphs on the network. In return, Delegators earn a percentage of all query fees and indexing rewards from the Indexer. Each Indexer sets the cut that will be rewarded to Delegators independently, creating competition among Indexers to attract Delegators. Most Indexers offer between 9-12% annually.
                                            </p>

                                            <p>
                                                For example, if a Delegator were to delegate 15k GRT to an Indexer offering 10%, the Delegator would receive ~1500 GRT in rewards annually.
                                            </p>

                                            <p>
                                                There is a 0.5% delegation tax which is burned whenever a Delegator delegates GRT on the network. If a Delegator chooses to withdraw their delegated GRT, the Delegator must wait for the 28-epoch unbonding period. Each epoch is 6,646 blocks, which means 28 epochs ends up being approximately 26 days.
                                            </p>

                                            <p>
                                                If you're reading this, you're capable of becoming a Delegator right now by heading to the network participants page, and delegating GRT to an Indexer of your choice.
                                            </p>

                                            <h1 className='docH1'> Curators (Earn GRT) </h1>
                                            <p>
                                                Curators identify high-quality subgraphs, and "curate" them (i.e., signal GRT on them) to earn curation shares, which guarantee a percentage of all future query fees generated by the subgraph. While any independent network participant can be a Curator, typically subgraph developers are among the first Curators for their own subgraphs because they want to ensure their subgraph is indexed.
                                            </p>

                                            <p>
                                                As of April 11th, 2024, subgraph developers are encouraged to curate their subgraph with at least 3,000 GRT. However, this number may be impacted by network activity and community participation.
                                            </p>

                                            <p>
                                                Curators pay a 1% curation tax when they curate a new subgraph. This curation tax is burned, decreasing the supply of GRT.
                                            </p>

                                            <h1 className='docH1'> Developers </h1>

                                            <p>
                                                Developers build and query subgraphs to retrieve blockchain data. Since subgraphs are open source, developers can query existing subgraphs to load blockchain data into their dapps. Developers pay for queries they make in GRT, which is distributed to network participants.
                                            </p>

                                            <h1 className='docH1'> Creating a subgraph </h1>

                                            <p>
                                                Developers can create a subgraph to index data on the blockchain. Subgraphs are instructions for Indexers about which data should be served to consumers.
                                            </p>

                                            <p>
                                                Once developers have built and tested their subgraph, they can publish their subgraph on The Graph's decentralized network.
                                            </p>

                                            <h1 className='docH1'> Querying an existing subgraph⁠⁠ </h1>
                                            <p>
                                                Once a subgraph is published to The Graph's decentralized network, anyone can create an API key, add GRT to their billing balance, and query the subgraph.
                                            </p>

                                            <p>
                                                Subgraphs are queried using GraphQL, and the query fees are paid for with GRT in Subgraph Studio. Query fees are distributed to network participants based on their contributions to the protocol.
                                            </p>

                                            <p>
                                                1% of the query fees paid to the network are burned.
                                            </p>

                                            <h1 className='docH1'> Indexers (Earn GRT) </h1>

                                            <p>
                                                Indexers are the backbone of The Graph. They operate independent hardware and software powering The Graph’s decentralized network. Indexers serve data to consumers based on instructions from subgraphs.
                                            </p>

                                            <p>
                                                Indexers can earn GRT rewards in two ways:
                                            </p>

                                            <ol>
                                                <li>
                                                    Query fees: GRT paid by developers or users for subgraph data queries. Query fees are directly distributed to Indexers according to the exponential rebate function (see GIP here).
                                                </li>

                                                <li>
                                                    Indexing rewards: the 3% annual issuance is distributed to Indexers based on the number of subgraphs they are indexing. These rewards incentivize Indexers to index subgraphs, occasionally before the query fees begin, to accrue and submit Proofs of Indexing (POIs) verifying that they have indexed data accurately.
                                                </li>
                                            </ol>

                                            <p>
                                                Each subgraph is allotted a portion of the total network token issuance, based on the amount of the subgraph’s curation signal. That amount is then rewarded to Indexers based on their allocated stake on the subgraph.
                                            </p>

                                            <p>
                                                In order to run an indexing node, Indexers must stake 100,000 GRT or more with the network. Indexers are incentivized to stake GRT in proportion to the amount of queries they serve.
                                            </p>

                                            <p>
                                                Indexers can increase their GRT allocations on subgraphs by accepting GRT delegation from Delegators, and they can accept up to 16 times their initial stake. If an Indexer becomes "over-delegated" (i.e., more than 16 times their initial stake), they will not be able to use the additional GRT from Delegators until they increase their stake in the network.
                                            </p>

                                            <p>
                                                The amount of rewards an Indexer receives can vary based on the initial stake, accepted delegation, quality of service, and many more factors. The following chart is publicly available data from an active Indexer on The Graph's decentralized network.
                                            </p>

                                            <h1 className='docH1'>The Indexer stake & reward of allnodes-com.eth ⁠</h1>

                                            <img src="../images/stakes.png" className='img-fluid' alt="stakes" />

                                            <p>This data is from February 2021 to September 2022.</p>

                                            <h1 className='docH1'> Token Supply: Burning & Issuance </h1>

                                            <p>
                                                The initial token supply is 10 billion GRT, with a target of 3% new issuance annually to reward Indexers for allocating stake on subgraphs. This means that the total supply of GRT tokens will increase by 3% each year as new tokens are issued to Indexers for their contribution to the network.
                                            </p>

                                            <p>
                                                The Graph is designed with multiple burning mechanisms to offset new token issuance. Approximately 1% of the GRT supply is burned annually through various activities on the network, and this number has been increasing as network activity continues to grow. These burning activities include a 0.5% delegation tax whenever a Delegator delegates GRT to an Indexer, a 1% curation tax when Curators signal on a subgraph, and a 1% of query fees for blockchain data.
                                            </p>

                                            <img src="../images/burnout.jpeg" className='img-fluid' alt="burnout" />

                                            <p>
                                                In addition to these regularly occurring burning activities, the GRT token also has a slashing mechanism in place to penalize malicious or irresponsible behavior by Indexers. If an Indexer is slashed, 50% of their indexing rewards for the epoch are burned (while the other half goes to the fisherman), and their self-stake is slashed by 2.5%, with half of this amount being burned. This helps to ensure that Indexers have a strong incentive to act in the best interests of the network and to contribute to its security and stability.
                                            </p>

                                            <h1 className='docH1'>
                                                Improving the Protocol
                                            </h1>

                                            <p>
                                                The Graph Network is ever-evolving and improvements to the economic design of the protocol are constantly being made to provide the best experience for all network participants. The Graph Council oversees protocol changes and community members are encouraged to participate. Get involved with protocol improvements in The Graph Forum.
                                            </p>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>
            </section >


        </>
    )
}

export default Docs